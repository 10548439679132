import React, { ElementType, FC } from 'react';
import Card from '../Card/Card';

type Props = {
  className?: string;
};

type ImageProps = {
  className?: string;
  as: ElementType;
  [key: string]: any;
};

const Image: FC<ImageProps> = ({ children, as: AS, ...props }) => (
  <AS className="w-full" {...props}>
    {children}
  </AS>
);

const Content: FC = ({ children }) => (
  <Card.Content className="bg-lighter-3 dark:bg-darker-3 space-y-4">
    {children}
  </Card.Content>
);

type TitleProps = {
  as: ElementType;
};

const Title: FC<TitleProps> = ({ as: AS = 'p', children }) => (
  <AS className="w-full text-2xl leading-6 text-black dark:text-white font-primary hover:underline lg:truncate">
    {children}
  </AS>
);

const Description: FC = ({ children }) => (
  <p className="ArticleCard__description text-sm text-black dark:text-white font-secondary">
    {children}
  </p>
);

type Child = {
  Content: FC;
  Image: FC<ImageProps>;
  Title: FC<TitleProps>;
  Description: FC;
};

const ArticleCard: FC<Props> & Child = ({ children, className = '' }) => (
  <Card>{children}</Card>
);

ArticleCard.Content = Content;
ArticleCard.Image = Image;
ArticleCard.Title = Title;
ArticleCard.Description = Description;

export default ArticleCard;
