import { useEffect, useState } from 'react';

export const useFacebookAnalytics = (
  eventName: string,
  shouldFireEvent: boolean
) => {
  const [hasFireEvent, setFireEvent] = useState(false);

  useEffect(() => {
    if (
      process.env.NODE_ENV === `production` &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.fbq === `function` &&
      !hasFireEvent &&
      shouldFireEvent
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq('track', eventName);
      setFireEvent(true);
    }
  }, [hasFireEvent]);
};
