import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ArticleContentList from '@kastle/components/src/components/Article/ArticleContentList';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import Text from '@kastle/ui/src/atoms/Text/Text';
import { HeadingTypes } from '@kastle/ui/src/atoms/Heading/Heading.types';
import { useTranslation } from 'react-i18next';
import CustomLayout from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';

const LatestArticles: FC = () => {
  const { t } = useTranslation();
  const { articles } = useStaticQuery(graphql`
    query lastArticlesSection {
      articles: allMdx(
        limit: 3
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { template: { eq: "post" } } }
      ) {
        edges {
          node {
            fields {
              slug
              path
              categories {
                name
                link
              }
              tags {
                name
                link
              }
            }
            excerpt
            timeToRead
            frontmatter {
              title
              tags
              categories
              thumbnail {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, width: 500, layout: FULL_WIDTH)
                }
              }
              date
              template
            }
          }
        }
      }
    }
  `);

  const list = articles.edges.map(({ node }) => node);

  return (
    <Section type={SectionType.Primary}>
      <CustomLayout>
        <CustomLayout.Head>
          <CustomLayout.Separator>
            <Heading as={HeadingTypes.h2}>{t('common.blog.title')}</Heading>
          </CustomLayout.Separator>
          <CustomLayout.Separator>
            <Text>{t('common.blog.description')}</Text>
          </CustomLayout.Separator>
        </CustomLayout.Head>
      </CustomLayout>
      <ArticleContentList articles={list} as="p" />
    </Section>
  );
};

export default LatestArticles;
