import React, { FC } from 'react';
import cx from 'classnames';

type Props = {
  className?: string;
  limit?: boolean;
  rounded?: boolean;
};

const Card: FC<Props> & { Content: FC<Props> } = ({
  children,
  className = '',
  limit = false,
  rounded = true,
}) => (
  <div
    className={cx('overflow-hidden shadow-lg', className, {
      'max-w-sm': limit,
      rounded: rounded,
    })}
  >
    {children}
  </div>
);

const CardContent: FC<Props> = ({ children, className = '' }) => (
  <div className={'px-6 py-6 ' + className}>{children}</div>
);

Card.Content = CardContent;

export default Card;
