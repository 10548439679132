require('./chateaudemorey.css');
require('@fontsource/crimson-text/400.css');
require('@fontsource/crimson-text/600.css');
require('@fontsource/montserrat/300.css');
require('@fontsource/montserrat/400.css');
require('@fontsource/montserrat/500.css');
require('@fontsource/montserrat/700.css');

export const onServiceWorkerUpdateReady = () => window.location.reload();

// let n;
//
// if (!window.fbq) {
//   n = window.fbq = function() {
//     n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
//   };
//   if (!window._fbq) window._fbq = n;
//   n.push = n;
//   n.loaded = !0;
//   n.version = '2.0';
//   n.queue = [];
// }
//
// window.fbq('init', '322480371283753');
// window.fbq('track', 'PageView');
//
// window.dataLayer = window.dataLayer || [];
//
// function gtag() {
//   window.dataLayer.push(arguments);
// }
// gtag('js', new Date());
// gtag('config', 'UA-64287890-1');
//
// exports.onRouteUpdate = function() {
//   if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
//     window.fbq('track', 'ViewContent');
//   }
// };

// exports.onClientEntry = () => {
//   document.addEventListener('readystatechange', event => {
//     // When window loaded ( external resources are loaded too- `css`,`src`, etc...)
//     if (event.target.readyState === 'complete') {
//       const t = document.createElement('script');
//       t.async = !0;
//       t.id = 'facebook-pixel';
//       t.src = 'https://connect.facebook.net/en_US/fbevents.js';
//       const s = document.getElementsByTagName('script')[0];
//
//       s.parentNode.insertBefore(t, s);
//     }
//   });
// };
