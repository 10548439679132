export const Routes = {
  fr: {
    BNB: '/chambres-dhotes',
    CASTLE: '/le-chateau',
    SPACE_RENTAL: '/location-salles-nancy-metz',
    FLAT: '/le-gite',
    HOBBY: '/les-loisirs-nancy-metz',
    CONTACT: '/contact',
    AIRBNB: '/chambres-dhotes/airbnb-nancy-metz',
  },
  en: {
    BNB: '/en/bed-and-breakfast',
    CASTLE: '/en/castle',
    SPACE_RENTAL: '/en/halls',
    FLAT: '/en/the-tower',
    HOBBY: '/en/leisure',
    CONTACT: '/en/contact',
    AIRBNB: '/en/bed-and-breakfast/airbnb-nancy-metz',
  },
  de: {
    BNB: '/de/bed-and-breakfast',
    CASTLE: '/de/castle',
    SPACE_RENTAL: '/de/halls',
    FLAT: '/de/the-tower',
    HOBBY: '/de/leisure',
    CONTACT: '/de/contact',
    AIRBNB: '/de/bed-and-breakfast/airbnb-nancy-metz',
  },
  nl: {
    BNB: '/nl/bed-and-breakfast',
    CASTLE: '/nl/castle',
    SPACE_RENTAL: '/nl/halls',
    FLAT: '/nl/the-tower',
    HOBBY: '/nl/leisure',
    CONTACT: '/nl/contact',
    AIRBNB: '/nl/bed-and-breakfast/airbnb-nancy-metz',
  },
};
