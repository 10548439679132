import React, { FC } from 'react';
import Text from '@kastle/ui/src/atoms/Text/Text';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import { HeadingTypes } from '@kastle/ui/src/atoms/Heading/Heading.types';
import Button from '@kastle/ui/src/atoms/Button/Button';
import { ButtonTypes } from '@kastle/ui/src/atoms/Button/Button.types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { graphql, Link, useStaticQuery } from 'gatsby';
import ButtonGroup from '@kastle/ui/src/atoms/Button/ButtonGroup';
import CustomLayout, {
  ContentRow,
  Separator,
} from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';

type Props = {
  langKey: string;
};

const Presentation: FC<Props> = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    {
      first: file(relativePath: { eq: "2020/09/chambres_d_hotes_nancy.jpeg" }) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, height: 130, layout: FULL_WIDTH)
        }
      }
      second: file(
        relativePath: {
          eq: "place_stanislas_nancy_proche_chateau_de_morey.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, height: 130, layout: FULL_WIDTH)
        }
      }
      third: file(
        relativePath: { eq: "2020/04/chateau_de_morey_mariage.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, height: 130, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Section type={SectionType.Primary}>
      <ContentRow>
        <CustomLayout>
          <Separator>
            <Heading as={HeadingTypes.h2}>
              {t('home.preview.bnb.title')}
            </Heading>
          </Separator>
          <Separator>
            <GatsbyImage
              image={data.first.childImageSharp.gatsbyImageData}
              style={{ height: '130px', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              objectFit="cover"
            />
          </Separator>
          <Separator>
            <Text>{t('home.preview.bnb.description')}</Text>
          </Separator>
          <Separator>
            <ButtonGroup>
              <Link
                to={t('common.nav.bnb.link')}
                aria-label={t('common.nav.bnb.name')}
              >
                <Button
                  variantType={ButtonTypes.Primary}
                  aria-label={t('common.nav.bnb.name')}
                >
                  {t('home.preview.bnb.cta')}
                </Button>
              </Link>
            </ButtonGroup>
          </Separator>
        </CustomLayout>
        <CustomLayout>
          <Separator>
            <Heading as={HeadingTypes.h2}>
              {t('home.preview.hobby.title')}
            </Heading>
          </Separator>
          <Separator>
            <GatsbyImage
              image={data.second.childImageSharp.gatsbyImageData}
              style={{ height: '130px', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              objectFit="cover"
            />
          </Separator>
          <Separator>
            <Text>{t('home.preview.hobby.description')}</Text>
          </Separator>
          <Separator>
            <ButtonGroup>
              <Link
                to={t('common.nav.hobby.link')}
                aria-label={t('common.nav.hobby.name')}
              >
                <Button
                  variantType={ButtonTypes.Primary}
                  aria-label={t('common.nav.hobby.name')}
                >
                  {t('home.preview.hobby.cta')}
                </Button>
              </Link>
            </ButtonGroup>
          </Separator>
        </CustomLayout>
        <CustomLayout>
          <Separator>
            <Heading as={HeadingTypes.h2}>
              {t('home.preview.space.title')}
            </Heading>
          </Separator>
          <Separator>
            <GatsbyImage
              image={data.third.childImageSharp.gatsbyImageData}
              style={{ height: '130px', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              objectFit="cover"
            />
          </Separator>
          <Separator>
            <Text>{t('home.preview.space.description')}</Text>
          </Separator>
          <Separator>
            <ButtonGroup>
              <Link
                to={t('common.nav.spaceRental.link')}
                aria-label={t('common.nav.spaceRental.name')}
              >
                <Button
                  variantType={ButtonTypes.Primary}
                  aria-label={t('common.nav.spaceRental.name')}
                >
                  {t('home.preview.space.cta')}
                </Button>
              </Link>
            </ButtonGroup>
          </Separator>
        </CustomLayout>
      </ContentRow>
    </Section>
  );
};
export default Presentation;
