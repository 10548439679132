import { graphql } from 'gatsby';
import { IoMdBed, GiCastle, MdBook, MdMap } from 'react-icons/all';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import FullImage from '@kastle/components/src/components/Image/FullImage';
import { CustomLayout, Head, ContentRow, ContentCol, Separator, SmallSpace } from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import Text from '@kastle/ui/src/atoms/Text/Text';
import LatestArticles from "../../../../src/sections/LatestArticles";
import Presentation from "../../../../src/sections/Presentation";
import { Routes } from "../../../../src/routes";
import * as React from 'react';
export default {
  graphql,
  IoMdBed,
  GiCastle,
  MdBook,
  MdMap,
  Section,
  SectionType,
  FullImage,
  CustomLayout,
  Head,
  ContentRow,
  ContentCol,
  Separator,
  SmallSpace,
  Heading,
  Text,
  LatestArticles,
  Presentation,
  Routes,
  React
};